/* ======================================================================
 component
====================================================================== */

/* =================================
 layout
================================= */

section {
  @include sp {
    overflow-x: hidden;
  }
}

.l_container {
  width: 100%;
  max-width: 1260px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &__not_max_width {
    max-width: 100%;
  }

  @include mq(768, 1140) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @include sp {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.col {
  position: relative;
  width: 100%;
  min-height: 1px;
}

/* =================================
 title
================================= */
.sec_title {
  color: $blue;

  > p {
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.1em;
    position: relative;
    display: inline-block;

    &::before {
      content: '';
      width: 7rem;
      height: 1px;
      background-color: $blue;
      position: absolute;
      top: 0;
      right: -76px;
      bottom: 0;
      margin: auto;
    }
  }

  :is(h2, h3) {
    font-size: 6rem;
    line-height: 1;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-family: $fontEn;
    margin-top: 1.2rem;
    overflow: hidden;

    span {
      opacity: 0;
      display: inline-block;
      transform: translateY(105%);

      &.space_inline {
        display: inline;
      }

      &.brackets {
        font-weight: 300;
      }
    }
  }

  &__white {
    color: #fff;

    > p {
      &::before {
        background-color: #fff;
      }
    }
  }

  &._show {
    :is(h2, h3) {
      span {
        opacity: 1;
        @for $i from 1 to 20 {
          &:nth-child(#{$i}) {
            animation: fadeInText 0.6s ease 0.1 * $i + s forwards;
          }
        }
      }

      @keyframes fadeInText {
        100% {
          transform: translateY(0);
        }
      }
    }
  }

  @include tab {
    > p {
      font-size: 1.4rem;
    }

    :is(h2, h3) {
      font-size: 5rem;
    }
  }

  @include sp {
    > p {
      font-size: 1.2rem;
    }

    :is(h2, h3) {
      font-size: 3.7rem;
      margin-top: 0.8rem;
    }
  }
}

/* =================================
 housing archive item
================================= */
.housing_archive_item {
  max-width: 365px;
  position: relative;
  padding-top: 14px;

  @include sp {
    max-width: 100%;
  }

  .thumbnail {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #f1f3f7;
    border-radius: 1rem;
    overflow: hidden;
    aspect-ratio: 450 / 338;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      transition: transform 0.3s ease, opacity 0.3s ease;
      aspect-ratio: 450 / 338;
    }

    &.closed {
      &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.45);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .closed-text {
      font-size: 2rem;
      line-height: 1;
      color: #ff0000;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
  }

  .ttl-row {
    display: flex;
    align-items: center;
    margin-top: 13px;
    column-gap: 10px;
  }

  .type {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 11rem;
    min-height: 3.2rem;
    padding: 8px;
    border-radius: 0.5rem 0.5rem 0.5rem 0;
    color: #fff;
    z-index: 3;

    &.type-new {
      background: #e4a100;
    }

    &.type-old {
      background: #009a33;
    }

    &.type-land {
      background: #374055;
    }
  }

  .tag {
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0.02em;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    min-height: 25px;
    padding: 5px;
    border-radius: 5px;
    color: #fff;

    &.tag-category {
      background: #374055;
    }
  }

  .ttl {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .price-row {
    display: flex;
    align-items: end;
    column-gap: 10px;
    margin-top: 10px;
  }

  .price {
    font-size: 2.5rem;
    line-height: 1;
    font-weight: bold;
    color: #ff5f27;

    small {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  .floor-plan {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .scale-list {
    display: flex;
    column-gap: 6px;
    margin-top: 9px;
    font-size: 1.4rem;

    @include sp {
      font-size: 1.2rem;
    }
  }

  .month-price-cell {
    border-top: 1px solid #d6d8dc;
    margin-top: 10px;
    padding-top: 8px;
    line-height: 1;
    dt,
    dd {
      display: inline-block;
    }
    dt {
      font-size: 1.4rem;
      line-height: 1;
      font-weight: bold;
      padding-right: 8px;
    }
    dd {
      font-size: 2rem;
      line-height: 1;
      font-weight: bold;
      color: #ff5f27;
      small {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }

  @include pc_tab {
    a:hover {
      .thumbnail {
        img {
          transform: scale(1.05);
          opacity: 0.8;
        }
      }
    }
  }
}

/* =================================
 sec description
================================= */
.sec_description {
  &__title {
    position: relative;
    > p {
      font-size: 1.4rem;
      line-height: 1;
      font-weight: 500;
      color: $blue;
      font-family: $fontEn;
    }

    > h2 {
      font-size: 3.4rem;
      line-height: 1.4;
      letter-spacing: 0.08em;
      font-weight: 700;
      color: #202d44;
      margin-top: 1.5rem;
    }

    &__num {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  > p {
    line-height: 2.1875;
    margin-top: 2.5rem;
  }

  @include sp {
    &__title {
      > p {
        font-size: 1rem;
      }

      > h2 {
        font-size: 2.2rem;
        line-height: 1.4;
        margin-top: 1rem;
      }
      &__num {
        height: 7.4rem;
        width: auto;
      }
    }
    > p {
      margin-top: 1.8rem;
      line-height: 1.8;
    }
  }
}

/* =================================
 arrow link button
================================= */
.arrow_link_button {
  display: block;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  max-width: 24.8rem;
  overflow: hidden;

  &::before {
    content: '';
    background-image: url(../img/common/link_arrow_b.svg);
    position: absolute;
    top: 0;
    right: 1.7rem;
    bottom: 0;
    margin: auto;
    width: 2.1rem;
    height: 2.1rem;
    z-index: 2;
  }

  > span {
    position: relative;
    display: block;
    padding: 1.8rem 2rem;
    border-radius: 1rem;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: '';
      transform-origin: left bottom;
      transform: scale(1, 1);
      transition: transform 0.3s;
      border-radius: 6px;
      background: $blue;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 6px;
      background-color: #ff5f27;
    }

    @include pc_tab {
      &:hover::before {
        transform-origin: left top;
        transform: scale(1, 0);
      }
    }
  }

  > span > span {
    font-size: 1.6rem;
    line-height: 1;
    color: #fefefe;
    font-weight: 700;
    padding-right: 2.1rem;
    position: relative;
    z-index: 2;

    @include sp {
      font-size: 1.5rem;
    }
  }

  &__border {
    border: 1px solid $white;
    > span {
      padding-left: 20px;
      padding-right: 20px;

      &::after {
        background-color: $white;
      }
    }
  }

  &__orange {
    background: #ff5f27;
    display: block;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    max-width: 100%;
    overflow: hidden;

    &::before {
      content: '';
      background-image: url(../img/common/link_arrow_o.svg);
      position: absolute;
      top: 0;
      right: 2.2rem;
      bottom: 0;
      margin: auto;
      width: 2.2rem;
      height: 2.2rem;
    }

    @include pc_tab {
      &:hover {
        &::before {
          background-image: url(../img/common/link_arrow_b.svg);
        }
      }
    }

    & > span {
      padding: 2.5rem 3.2rem 2.5rem 3.2rem;
      &::before {
        background-color: #ff5f27;
      }

      &::after {
        background: $blue;
      }
    }

    & > span > span {
      font-size: 1.9rem;
      line-height: 1;
      color: #fefefe;
      font-weight: 700;
    }
  }

  &.search {
    text-align: center;
    max-width: 29.8rem;
    margin: auto;

    &::before {
      content: '';
      background-image: url(../img/ico/lense.svg);
      background-size: contain;
      position: absolute;
      top: 0;
      left: 5.2rem;
      right: inherit;
      bottom: 0;
      margin: auto;
      width: 1.7rem;
      height: 1.7rem;
      z-index: 2;
    }

    &:hover {
      &::before {
        background-image: url(../img/ico/lense.svg);
      }
    }

    > span > span {
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      font-size: 1.6rem;
      line-height: 1;
      font-weight: 700;
      letter-spacing: 0.06em;
    }
  }

  @include pc_tab {
    &:hover {
      &::before {
        background-image: url(../img/common/link_arrow_o.svg);
      }
    }

    &__border {
      &:hover {
        &::before {
          background-image: url(../img/common/link_arrow_w.svg);
        }

        > span > span {
          color: $blue;
        }
      }
    }
  }
}

/* =================================
 anchor link button
================================= */
.anchor_link_button {
  display: block;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  max-width: 24.8rem;
  overflow: hidden;

  &__border {
    border: 1px solid $white;
    > span {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &::before {
    content: '';
    background-image: url(../img/common/arrow_bottom.svg);
    position: absolute;
    top: 0;
    right: 1.7rem;
    bottom: 0;
    margin: auto;
    width: 2.1rem;
    height: 2.1rem;
    z-index: 2;
  }

  @include pc_tab {
    &:hover {
      &::before {
        background-image: url(../img/common/arrow_bottom_o.svg);
      }
    }
  }

  > span {
    position: relative;
    display: block;
    padding: 1.8rem 2rem;
    border-radius: 1rem;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: '';
      transform-origin: left bottom;
      transform: scale(1, 1);
      transition: transform 0.3s;
      border-radius: 6px;
      background: $blue;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      border-radius: 6px;
      background-color: #ff5f27;
    }

    @include pc_tab {
      &:hover::before {
        transform-origin: left top;
        transform: scale(1, 0);
      }
    }
  }

  > span > span {
    font-size: 1.6rem;
    line-height: 1;
    color: #fefefe;
    font-weight: 700;
    padding-right: 2.1rem;
    position: relative;
    z-index: 2;
  }
}

/* =================================
 pager
================================= */
.c_pager_num {
  display: flex;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
  padding-top: 7.2rem;
  padding-bottom: 14.2rem;

  @include sp {
    padding-top: 4rem;
    padding-bottom: 7.2rem;
  }
  > a,
  > span {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: YakuHanJP, 'Jost', sans-serif;
  }
  .prev,
  .next,
  .dots {
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prev,
  .next {
    font-size: 0;
  }
  a.page-numbers:not(.prev):not(.next):not(.dots) {
    color: #2c2c2c;
    background: #f1f3f7;
    border-radius: 50%;
    &:hover {
      color: #fff;
      background: $blue;
      transition: background 0.3s ease, color 0.3s ease;
    }
  }
}

.c_pager_prev_next {
  .back {
    margin-top: 66px;
    a {
      max-width: 262px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      background: $blue;
      border-radius: 10px;
      min-height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: relative;
      box-sizing: border-box;
      padding: 10px 40px;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 16px;
      bottom: 0;
      margin: auto;
    }
    span {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.06em;
      color: #fff;
      text-align: center;
    }
  }
}

.gtranslate_wrapper {
  position: relative;
  min-height: 2.5rem;
  max-width: 15.4rem;
  display: block;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 2.5rem;
    pointer-events: none;
    background-color: $blue;
    z-index: 1;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.9rem;
    right: 0.7rem;
    background-image: url(../img/common/bottom_arrow.svg);
    background-repeat: no-repeat;
    width: 1rem;
    height: 0.7rem;
    pointer-events: none;
    z-index: 2;
  }

  select {
    font-size: 1.3rem;
    line-height: 1.5;
    color: $color_1;
    font-family: $fontEn;
    font-weight: 500;
    position: relative;
    width: 100%;
    padding: 0.2rem 3.5rem 0.2rem 2.6rem;
    min-height: 2.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    max-width: 15.4rem;
    appearance: none;
    background-color: $white;
    background-image: url(../img/common/world.svg);
    background-size: 1.3rem 1.3rem;
    background-position: 0.8rem center;
    background-repeat: no-repeat;
    &:invalid {
      color: #a3aab6;
    }
  }
}

/* =================================
 page title
================================= */
.page_title_wrap {
  position: absolute;
  left: 6vw;
  bottom: 6.785vw;
  max-width: 42vw;

  .ja_title {
    font-size: 1.574vw;
    line-height: 2.857vw;
    > span {
      &::before {
        height: 2.857vw;
      }
    }
  }

  .en_title {
    font-size: 4.6vw;
    line-height: 5.107vw;
    font-family: $fontEn;
    > span {
      &::before {
        height: 5.107vw;
      }
    }
  }

  .page_title {
    font-weight: bold;
    color: #fff;
    opacity: 0;

    > span {
      position: relative;
      display: inline-block;
      padding: 0 1.2rem;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;

      &::before {
        content: '';
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        transition: width 0.5s ease;
      }

      > span {
        display: inline-block;
        transform: translateY(-25%);
        transition: all 0.6s ease;
        opacity: 0;

        &.is_show {
          transform: translateY(0);
          opacity: 1;
        }

        &.space_inline {
          display: inline;
        }

        &.brackets {
          font-weight: 300;
        }
      }
    }

    &.page_title_animation {
      opacity: 1;
      > span {
        &::before {
          width: 100%;
        }
      }
    }

    &.letter_animation {
      > span > span {
        opacity: 1;
      }
    }
  }

  @include sp {
    position: absolute;
    left: 20px;
    bottom: 24.53vw;
    right: inherit;

    .ja_title {
      font-size: 3.73vw;
      line-height: 6.266vw;
      letter-spacing: 0.1em;
      > span {
        padding: 0 1.1rem;
        &::before {
          height: 6.266vw;
        }
      }
    }

    .en_title {
      font-size: 9.64vw;
      line-height: 9vw;
      letter-spacing: 0.05em;
      > span {
        padding: 0 1.1rem;
        &::before {
          height: 11.2vw;
        }
      }
    }
  }
}

/* =================================
 banner_form_link
================================= */
.banner_form_link {
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
  background: #f6e742;
  padding: 3.3rem 2.5rem 2.4rem;
  border-radius: 1rem;
  box-shadow: 0px 0.5rem 0.8rem 0px rgba(0, 0, 0, 0.16);
  z-index: 99;

  &::before {
    content: '';
    position: absolute;
    top: -2.7rem;
    right: -0.4rem;
    bottom: 0;
    background-image: url(../img/top/form_illust.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 11.1rem;
    height: 15.5rem;
    z-index: 0;
  }

  #banner_form_link__close {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 0;

    img {
      display: block;
    }
  }

  > p {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 700;
    color: $blue;
    padding: 0.9rem 2.5rem 2.4rem;
    background: url(../img/top/baloon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    z-index: 1;
    text-align: center;

    > span {
      color: #ff5f27;
    }
  }

  > .arrow_link_button {
    position: relative;
    z-index: 1;
  }
}
