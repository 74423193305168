/* ======================================================================
 base
====================================================================== */

html {
  font-size: 62.5%; // 1em = 10px
  @include mq(768, 1140) {
    font-size: 55%;
  }
}

body {
  font-size: 1.6rem;
  color: $color_1;
  font-family: $font_1;
  letter-spacing: 0.06em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  overflow-x: hidden;
  @include pc_tab {
    width: 100%;
    // min-width: $content_width;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

html body {
  top: inherit !important;
}

.en {
  font-family: YakuHanJP, lato, sans-serif;
  font-weight: 700;
}

* {
  backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* h1 - h6 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin-block-start: 0;
  margin-block-end: 0;
}

/* a */
// 各ページで色を変更するときは@include a_tagを使用して上書きしてください。
a {
  text-decoration: none;
  &:link {
    color: $color_1;
    cursor: pointer;
  }
  &:visited {
    color: $color_1;
  }
  &:hover {
    color: $color_1;
  }
  &:active {
    color: $color_1;
  }
}

// モバイル時 電話番号カラー
a[href^='tel'] {
  color: $color_1;
}

/* Text Highlight */
// ::selection {
//   background-color: $color_1;
// }

// focus時のoutline削除
*:focus {
  outline: none;
}

/* button */
button {
  display: block;
}

textarea {
  resize: vertical;
}

select {
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $color_1;
  }
}

/* placeholder */
// 各ページで色を変更するときは@include placeholderを使用して上書きしてください。
input,
textarea {
  @include placeholder($color_1);
  &:focus {
    @include placeholder($color_1);
  }
}

// ドラッグ禁止
img {
  pointer-events: none;
  user-select: none;
}

img {
  max-width: 100%;
  height: auto;
}

.js_window_in_one {
  overflow: hidden;
  &:not(.sec_title) {
    transform: translateY(3rem);
    transition: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1),
      opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;

    &._show {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* WORDPRESS投稿内表示用テンプレート
==================================*/
.wp_content {
  font-size: 16px;

  @include sp {
    font-size: 14px;
  }

  p {
    margin-bottom: 10px;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.8em;
    line-height: 1.625;
    font-weight: 500;
  }

  h2 {
    font-size: 1.5em;
    line-height: 1.625;
    font-weight: 500;
  }

  h3 {
    font-size: 1.17em;
    line-height: 1.625;
    font-weight: 500;
  }

  h4 {
    font-size: 1em;
    line-height: 1.625;
    font-weight: 500;
  }

  h5 {
    font-size: 0.83em;
    line-height: 1.625;
    font-weight: 500;
  }

  h6 {
    font-size: 0.67em;
    line-height: 1.625;
    font-weight: 500;
  }

  p strong {
    font-weight: 700;
    color: #1d3a66;
  }

  p em {
    font-style: italic;
    color: #1d3a66;
  }

  p a {
    font-size: 1em;
    line-height: 2.1;
    text-decoration: underline;
    transition: all 0.3s;
  }

  p a:hover {
    color: #34b5be !important;
  }

  hr {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  video,
  iframe {
    width: 100% !important;
    height: 46.44vw !important;
  }

  img {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    height: auto;
    max-width: 100%;
    height: auto;

    &.aligncenter {
      margin-left: auto;
      margin-right: auto;
    }

    &.alignleft {
      margin-left: 0;
      margin-right: auto;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 2rem;
    margin: 1rem 0;
  }

  ol {
    list-style-type: decimal;
    padding-left: 2rem;
    margin: 1rem 0;
  }
}
