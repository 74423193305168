/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: inherit;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none !important;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none !important;
    }
  }
}

@include sp {
  .u_pc {
    display: none !important;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none !important;
    }
  }
  .u_sp {
    display: inherit;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 font family
================================= */

.font_en {
  font-family: $fontEn;
}
