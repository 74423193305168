/* ======================================================================
 layout
====================================================================== */

/* =================================
 l_header
================================= */
.l_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  background: transparent;
  transition: background 0.3s ease;
  z-index: 99;

  .container {
    margin-left: auto;
    margin-right: auto;
    @include mq_min(1141) {
      padding-left: 45px;
      padding-right: 50px;
    }
  }

  .header_inner {
    padding: 4rem 0 3rem;
    display: flex;
    justify-content: space-between;
    transition: padding 0.3s ease;

    &_logo_wrap {
      flex: 0 0 430px;
      max-width: 430px;
      margin-block-start: 0;
      margin-block-end: 0;
      display: flex;
      align-items: flex-end;
      column-gap: 3rem;

      @include mq(768, 1140) {
        align-items: flex-end;
        column-gap: 1.6rem;
        justify-content: end;
        flex: 0 0 33.333%;
        max-width: 33.333%;
      }
    }

    .logo {
      @include pc_tab {
        a {
          transition: all 0.3s $ease;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .logo_catch {
        padding: 16px 0 0;
        font-size: 1.2rem;
        line-height: 1.333;
        display: block;
      }
    }

    .menu_wrap {
      flex: 0 0 calc(100% - 430px);
      max-width: calc(100% - 430px);
      margin-block-start: 0;
      margin-block-end: 0;

      .g_menu_list {
        display: flex;
        justify-content: flex-end;
        padding-top: 7px;
        > .g_menu_li {
          margin-right: 26px;
          font-size: 1.4rem;
          line-height: 1.4285;
          letter-spacing: 0;
          font-weight: 700;
          position: relative;
          padding-bottom: 30px;
          &:last-child {
            margin-right: 0;
            a {
              padding: 8px 16px;
              background-color: #fff;
              border-radius: 25px;
              color: $color_1;
              transition: all 0.3s $ease;
              &:visited {
                color: $color_1;
              }
              &:hover {
                background-color: $color_1;
                color: $white;
              }
            }
          }

          &.pull_li {
            .pull_parent {
              border-bottom: 4px solid transparent;
              transition: border 0.3s $ease;
              padding-bottom: 5px;
            }
            &.h_active {
              .pull_parent {
                border-color: $color_4;
              }
            }
          }
        }
      }

      @include mq(768, 1140) {
        flex: 0 0 66%;
        max-width: 66%;

        .g_menu_list {
          justify-content: flex-end;
          .g_menu_li {
            margin-right: 18px;
          }
        }
      }
    }

    .pull_down {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }

    .dial {
      display: flex;
      align-items: end;
      flex-direction: column;

      ruby {
        display: block;
        font-size: 8px;
        line-height: 1;
        letter-spacing: 0.5em;
        color: #fff;
        margin-right: -0.2rem;
        text-align: right;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 23px;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-family: $fontEn;
      }
    }

    .top_button_list {
      display: flex;
      align-items: center;
      column-gap: 6px;
      margin-left: 16px;
    }

    .top_button {
      padding: 10px 14px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      height: 35px;
      position: relative;
      border-radius: 6px;
      span {
        font-size: 1.3rem;
        line-height: 1;
        color: #fff;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
        column-gap: 6px;
        position: relative;
        z-index: 1;
      }
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: '';
        transform-origin: left bottom;
        transform: scale(1, 1);
        transition: transform 0.3s;
        border-radius: 6px;
      }
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        border-radius: 6px;
      }

      @include pc_tab {
        &:hover::before {
          transform-origin: left top;
          transform: scale(1, 0);
        }
      }
      &.mail {
        transition: background-color 0.3s ease;

        &::before {
          background-color: #ff5f27;
        }

        &::after {
          background-color: #ec2c00;
        }
      }
      &.line {
        transition: background-color 0.3s ease;

        &::before {
          background-color: #00b900;
        }

        &::after {
          background-color: #00971c;
        }
      }
    }

    .menu_top {
      display: flex;
      align-items: center;
      justify-content: end;
      transition: opacity 0.3s ease, height 0.3s ease;
    }

    .header_inner__nav {
      margin-top: 1.6rem;
      display: block;
      > ul {
        display: flex;
        justify-content: end;
        column-gap: 1.7rem;
        li a {
          font-size: 1.5rem;
          line-height: 1;
          color: #fff;
          font-weight: 700;
          padding: 0.7rem 0.2rem;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 0;
            transform: translateX(-50%);
            pointer-events: none;
            background-size: 6px 1px;
            background-image: linear-gradient(
              to right,
              #fff,
              #fff 1px,
              transparent 1px,
              transparent 5px
            );
            background-repeat: repeat-x;
            width: 100%;
            height: 1px;
            transition: opacity 0.3s ease;
            opacity: 0;
          }

          &.current,
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &.scroll_active {
    background: #202d44;

    .header_inner {
      padding: 2.1rem 0 1.8rem;

      .menu_top {
        height: 0;
        opacity: 0;
      }
    }
  }

  @include tab {
    .header_inner {
      .header_inner__nav {
        ul {
          column-gap: 1rem;
          a {
            font-size: 1.2rem !important;
          }
        }
      }

      .dial {
        ruby {
          letter-spacing: 0em;
          margin-right: -1px;
        }
        span {
          font-size: 1.7rem;
          white-space: nowrap;

          img {
            max-width: 1.6rem;
          }
        }
      }

      .top_button {
        padding: 0.6rem 1rem;
        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.translated-ltr {
  .l_header {
    top: 3.9rem;
  }
}

/* =================================
 l_header_sp
================================= */
.l_header_sp {
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 10;
  transform: translate(0);
  transition: background 0.3s ease;

  &.scroll_active {
    background: #202d44;
  }

  &__container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__header_inner {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo_wrap {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 10px 15px;

      .logo {
        display: block;
        img {
          width: 18.1rem;
        }
      }

      .catch {
        display: block;
        font-size: 1.2rem;
        line-height: 1.33333333;
        margin-left: 9px;
      }
    }
  }

  &__right_wrap {
    position: fixed;
    top: 1.5rem;
    right: 1.9rem;
    z-index: 998;
    display: flex;
    align-items: center;
    margin-right: -2rem;
    :is(.phone-wrap, .button-wrap) {
      padding: 0.3rem 1.5rem;
    }

    .button-wrap {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 0;
      width: 6rem;
      height: 3rem;
    }
  }
}

.translated-ltr {
  .l_header_sp {
    top: 3.9rem;
  }
  .l_header_sp__right_wrap {
    top: 5.4rem;
  }
}

/* =================================
 l_menu_sp
================================= */
.l_menu_sp {
  position: relative;
  z-index: 999;
  background-color: #202d44;
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding-top: 8rem;
  padding-bottom: 5rem;
  overflow-x: hidden;

  .gtranslate_wrap {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  &__right_wrap {
    position: absolute;
    top: 1.5rem;
    right: 1.9rem;
    z-index: 999;
    display: flex;
    align-items: center;
    margin-right: -2rem;
    :is(.phone-wrap, .button-wrap) {
      padding: 0.3rem 1.5rem;
    }

    .button-wrap {
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 0;
      width: 6rem;
      height: 3rem;
    }
  }

  &.l_menu_sp_active {
    pointer-events: auto;
    opacity: 1;
  }

  nav {
    -webkit-overflow-scrolling: touch;
    .g_menu_list {
      max-width: 23.2rem;
      margin: auto;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          to right,
          #fff,
          #fff 1px,
          transparent 1px,
          transparent 1px
        );
        background-size: 7px 1px;
        background-repeat: repeat-x;
        width: 100%;
        height: 1px;
      }

      > .g_menu_li {
        display: block;
        position: relative;
        text-align: center;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(
            to right,
            #fff,
            #fff 1px,
            transparent 1px,
            transparent 1px
          );
          background-size: 7px 1px;
          background-repeat: repeat-x;
          width: 100%;
          height: 1px;
        }

        a {
          padding: 1.5rem 2rem 1.6rem;
          font-size: 1.6rem;
          line-height: 1;
          font-weight: 400;
          letter-spacing: 0.08em;
          color: $white;
          display: block;
        }
      }
    }
  }

  &__contact_area {
    padding: 3.3rem 0 0;
    background: none;

    &__text {
      font-size: 1.7rem;
      line-height: 1.5;
      font-weight: 700;
      letter-spacing: 0.08em;
      color: $white;
      text-align: center;
    }

    &__bottom {
      display: block;
      margin-top: 22px;
    }

    &__dial_wrapper {
      display: flex;
      justify-content: center;
    }

    &__dial {
      display: flex;
      align-items: end;
      flex-direction: column;
      ruby {
        display: block;
        font-size: 1rem;
        line-height: 1;
        letter-spacing: 1.25em;
        color: $white;
        margin-right: -0.5em;
        text-align: right;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 3.7rem;
        color: $white;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.06em;
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-family: $fontEn;
        a {
          color: $white;
        }

        img {
          @-moz-document url-prefix() {
            margin-top: 0.6rem;
          }
        }
      }
    }

    &__button_list {
      display: flex;
      flex-direction: column;
      align-items: center;
      column-gap: 1.8rem;
      margin-top: 1.8rem;
      gap: 10px;
    }

    &__button {
      padding: 25px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;
      max-width: 335px;
      min-width: 335px;
      span {
        font-size: 1.9rem;
        line-height: 1;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
      &.mail {
        background-color: #ff5f27;
      }
      &.line {
        background-color: #00b900;
      }
    }
  }
}

.translated-ltr {
  .l_menu_sp {
    top: 3.9rem;
  }
}

.button-wrap {
  margin: auto;
  width: 60px;
  height: 60px;
  z-index: 100;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  .hamburger {
    position: relative;
    .line {
      width: 3rem;
      height: 0.2rem;
      display: block;
      margin: 5px auto;
      transition: all 0.3s ease-in-out;
      background-color: $white;
    }

    &.is-active {
      .line:nth-child(2) {
        opacity: 0;
      }

      .line:nth-child(1) {
        background-color: $white;
        transform: translateY(7px) rotate(45deg);
      }

      .line:nth-child(3) {
        background-color: $white;
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }
}

/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  padding-top: 2.5rem;
  ol {
    font-size: 1.2rem;
    line-height: 1;
    display: flex;
    column-gap: 10px;
    li {
      font-size: 1.2rem;
      line-height: 1;
      a {
        font-size: 1.2rem;
        color: $blue;
      }

      &:first-child a {
        font-weight: bold;
      }

      &[aria-current='page'] {
        font-size: 1.2rem;
        color: #a3aab6;
      }
    }
  }

  @include sp {
    display: none;
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
}

/* =================================
 l_content
================================= */
.l_content {
}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
}

/* =================================
 l_footer
================================= */
#bannerArea {
  position: relative;
  padding: 95px 110px 102px;

  .link_ttl {
    text-align: center;
    .border {
      font-size: 4rem;
      line-height: 1;
      letter-spacing: 0.1em;
      font-weight: 800;
      margin-bottom: 30px;
      display: inline-block;
      position: relative;
      padding-left: 105px;
      padding-right: 105px;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: -5px;
        margin: auto;
        background-color: $color_1;
        border-radius: 4px;
        width: 80px;
        height: 2px;
      }
      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }

  .swiper-slide {
    text-align: center;
    a {
      display: block;
      transition: all 0.3s $ease;
      @include pc_tab {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    &::after {
      content: '';
      width: 40px;
      height: 40px;
      background-image: url(../img/common/arw_circle.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin: auto;
    }
  }
  .swiper-button-next {
    right: 16.5%;
  }
  .swiper-button-prev {
    left: 16.5%;
    &::after {
      transform: scale(-1, 1);
    }
  }
  @include mq_max(1200) {
    padding: 100px 40px;
  }

  @include sp {
    padding: 76px 10px 88px;

    .link_ttl {
      padding-left: 10px;
      padding-right: 10px;
      .border {
        background-color: #fff;
        display: block;
        position: relative;
        padding-left: 25vw;
        padding-right: 25vw;
        font-size: 10.67vw;
        &::before,
        &::after {
          width: calc(50% - 85px);
        }
      }
    }
    .swiper-container {
      .swiper-slide {
        text-align: center;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
      &::after {
        transform: scale(-1, 1);
      }
    }
  }
}

.privacy_m {
  @include pc_tab {
    position: absolute;
    top: -110px;
    right: 50px;
  }

  @include sp {
    position: absolute;
    right: inherit;
    left: 14px;
    bottom: 10px;
  }
}

.l_footer {
  position: relative;

  .t_footer {
    padding: 64px 0 60px;
    background: $gray;

    &_text {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 700;
      letter-spacing: 0.08em;
      color: $blue;
      text-align: center;
    }

    &_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 37px;
    }

    &_dial {
      display: flex;
      align-items: end;
      flex-direction: column;
      ruby {
        display: block;
        font-size: 11px;
        line-height: 1;
        letter-spacing: 1.6em;
        color: $blue;
        margin-right: -9px;
        text-align: right;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 4.5rem;
        color: $blue;
        font-weight: 700;
        line-height: 1;
        letter-spacing: 0.06em;
        display: flex;
        align-items: center;
        column-gap: 7px;
        font-family: $fontEn;
      }
    }

    &_button_list {
      display: flex;
      align-items: center;
      column-gap: 1.8rem;
      margin-left: 4rem;

      @include sp {
        > li {
          max-width: 33.5rem;
          width: 100%;
        }
      }
    }

    &_button {
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      min-width: 32.5rem;
      overflow: hidden;
      position: relative;
      padding: 0;

      @include mq_max(1100) {
        min-width: 25.5rem;
      }

      @include sp {
        max-width: 33.5rem;
        min-width: 100%;
      }

      > span {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 2.5rem;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          content: '';
          transform-origin: left bottom;
          transform: scale(1, 1);
          transition: transform 0.3s;
          border-radius: 6px;
        }

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          border-radius: 6px;
        }

        @include pc_tab {
          &:hover::before {
            transform-origin: left top;
            transform: scale(1, 0);
          }
        }
      }

      > span > span {
        font-size: 18px;
        line-height: 1;
        color: #fff;
        font-weight: 700;
        display: flex;
        align-items: center;
        column-gap: 10px;
        position: relative;
        z-index: 2;
      }

      &.mail {
        > span {
          &::before {
            background-color: #ff5f27;
          }

          &::after {
            background-color: #ec2c00;
          }
        }
      }
      &.line {
        > span {
          &::before {
            background-color: #00b900;
          }

          &::after {
            background-color: #00971c;
          }
        }
      }
    }

    @include sp {
      &_text {
        font-size: 2.1rem;
      }
      &_bottom {
        display: block;
        margin-top: 22px;
      }

      &_dial_wrapper {
        display: flex;
        justify-content: center;
      }

      &_dial {
        align-items: end;
        ruby {
          text-align: right;
          font-size: 1rem;
          letter-spacing: 1.4em;
        }
        span {
          font-size: 3.7rem;
          white-space: nowrap;

          img {
            max-width: 3rem;
            @-moz-document url-prefix() {
              margin-top: 0.6rem;
            }
          }
          _::-webkit-full-page-media,
          _:future,
          :root img {
            margin-top: 0.6rem;
          }
        }
      }

      &_button_list {
        margin-top: 21px;
        flex-direction: column;
        gap: 10px;
        margin-left: 0;
      }
    }

    @include tab {
      &_bottom {
        flex-wrap: wrap;
      }
      &_dial_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
  }

  .m_footer_inner {
    padding-top: 3.7rem;
    padding-bottom: 3.7rem;

    .m_footer_list {
      display: flex;
      margin-left: -2.5rem;
      margin-right: -2.5rem;
    }

    .m_footer_item {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      &:not(:last-of-type) {
        border-right: 1px solid #d9dce0;
      }
    }

    .m_footer_item_title {
      margin-top: 11px;
      margin-bottom: 6px;
      > :is(strong, span) {
        line-height: 1;
        font-weight: 700;
      }
      > span {
        font-size: 12px;
        display: inline-block;
      }
      > strong {
        font-size: 14px;
        display: inline-block;
      }
    }

    .m_footer_item_img {
      border-radius: 5px;
      overflow: hidden;
    }

    :is(.m_footer_item_address, .m_footer_item_tel) {
      font-size: 1.2rem;
      line-height: 1.8rem;

      span {
        display: inline-block;
      }
    }

    @include sp {
      .m_footer_list {
        display: flex;
        flex-direction: column;
        margin: -1.8rem 0;
      }

      .m_footer_item {
        padding: 1.8rem 0;
        &:not(:last-of-type) {
          border-right: 0;
          border-bottom: 1px solid #d9dce0;
        }
      }

      .m_footer_item_img {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .b_footer {
    background-color: $blue;
    padding: 4rem 0 9.2rem;
    color: #fff;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      bottom: 1.5rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 433.5rem;
      height: 5.5rem;
      background-image: url(../img/common/footer_image.svg);
      background-position: -97rem center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.5;
    }

    a {
      color: #fff;
    }

    &_logo {
      margin-bottom: 2.6rem;
    }
  }

  .b_footer_inner {
    display: grid;
    justify-content: space-between;
    height: 100%;
    grid-template-areas:
      'footer-logo footer-nav'
      'footer-policy footer-nav';
    nav ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 18px 0;
      li {
        font-size: 1.4rem;

        &::before {
          content: '・ ';
        }
      }
    }

    @include sp {
      nav ul {
        grid-template-columns: 1fr 1.6fr;

        li {
          a {
            font-size: 1.4rem;
          }
          &:nth-child(1) {
            order: 0;
          }
          &:nth-child(2) {
            order: 6;
          }
          &:nth-child(3) {
            order: 3;
          }
          &:nth-child(4) {
            order: 2;
          }
          &:nth-child(5) {
            order: 8;
          }
          &:nth-child(6) {
            order: 5;
          }
          &:nth-child(7) {
            order: 4;
          }
          &:nth-child(8) {
            order: 1;
          }
          &:nth-child(9) {
            order: 7;
          }
        }
      }
    }
  }

  .b_footer_logo {
    grid-area: footer-logo;
  }

  .b_footer_footer_nav {
    grid-area: footer-nav;
  }

  .b_footer_policy {
    grid-area: footer-policy;
    ul {
      display: flex;
      margin-left: -15px;
      margin-right: -15px;
      li {
        font-size: 14px;
        line-height: 1;
        padding-left: 15px;
        padding-right: 15px;
        &:not(:last-child) {
          border-right: 1px solid rgba(255, 255, 255, 0.2);
        }

        a {
          display: inline-flex;
          column-gap: 0.6rem;
          align-items: center;
        }
      }
    }

    .copyright {
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1.9rem;
      font-family: $fontEn;
    }

    @include sp {
      margin-top: 2.5rem;
      padding-top: 2.5rem;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  @include pc_tab {
    .b_footer_footer_nav {
      a {
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  @include mq(768, 1000) {
    .b_footer_inner .row {
      .c_info_col {
        flex: 0 0 32%;
        max-width: 32%;
        .fot_c_name {
          strong {
            display: block;
          }
        }

        .address {
          line-height: 1.6;
          margin-top: 8px;
        }

        .u_sp {
          display: block;
        }
      }

      .copy_col {
        flex: 0 0 68%;
        max-width: 68%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  @include sp {
    padding-bottom: 7.5rem;
    .b_footer_inner {
      grid-template-areas:
        'footer-logo'
        'footer-nav'
        'footer-policy';
      grid-template-columns: 1fr;
    }
  }
}

/* =================================
 l fixed footer
================================= */
.l_fixed_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 20;
  &__inner {
    background: #79818f;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    padding: 0.8rem 1rem;
    column-gap: 0.5rem;
  }

  a {
    display: flex;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0.7rem 1rem;
    border-radius: 0.5rem;
  }

  &__mail {
    background: #ff5f27;
  }
  &__line {
    background: #00b900;
  }
  &__form {
    background: #f1e020;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      background-image: url(../img/common/lens.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 3rem;
      height: 3rem;
      transform: translate(5.5rem, -1.1rem) rotate(-40deg);
      z-index: 4;
    }
  }
}

/* ======================================================================
 layout p_top
====================================================================== */

/* =================================
 l_main_visual_top
================================= */
.l_main_visual_top {
}

/* =================================
 l_main_visual_top
================================= */
.under_page_mv {
  position: relative;
}

#loaderImage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #f1f3f7;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;

  .loader {
    width: 40px;
    height: 40px;
    --c: no-repeat linear-gradient(orange 0 0);
    background: var(--c), var(--c), var(--c), var(--c);
    background-size: 21px 21px;
    animation: loader-animation-l5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
  }
  @keyframes loader-animation-l5 {
    0% {
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
    33% {
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      width: 60px;
      height: 60px;
    }
    66% {
      background-position: 100% 0, 100% 100%, 0 100%, 0 0;
      width: 60px;
      height: 60px;
    }
    100% {
      background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    }
  }
}

// CSS hack safari
_::-webkit-full-page-media,
_:future,
:root .l_header .header_inner .dial span img {
  margin-top: 0.4rem;
}

_::-webkit-full-page-media,
_:future,
:root .l_menu_sp__contact_area__dial span img {
  margin-top: 0.5rem;
}

_::-webkit-full-page-media,
_:future,
:root .l_footer .t_footer_dial span img {
  margin-top: 0.4rem;

  @include sp {
    margin-top: 0.6rem;
  }
}
