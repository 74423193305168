/* ======================================================================
 common style index
・reset
・variable
・mixin
・base
・utility
・component
・layout
====================================================================== */

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500;700&family=Noto+Sans+JP:wght@400;500;700&display=swap');

@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css');

// リセット
@import 'reset';

// 変数定義
@import 'variable';

// ミックスイン
@import 'mixin';

// ベース
@import 'base';

// 汎用クラス
@import 'utility';

// コンポーネント
@import 'component';

// レイアウト
@import 'layout';
